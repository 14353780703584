import React from "react"
import Layout from "../components/layout"

import "../index.scss"

const PageNotFound = () => {
  return (
    <Layout>
      <h1>404 Error</h1>
      <h2>Oops! This page doesn't exist</h2>
      <p>
        <a href="/">Click here to go back home</a>
      </p>
    </Layout>
  )
}

export default PageNotFound
